// [data-form-submit-error-text],
// [data-form-submit-success-text] {
//     &.invisible {
//         opacity: 0 !important;
//         transition: opacity 0.3s;
//     }
// }
//
// .bttn_copy .popup_result,
// .bttn_submit [data-form-submit-error-text],
// .bttn_submit [data-form-submit-success-text] {
//     box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
//     position: absolute;
//     padding-left: 52rem;
//     padding-top: 10rem;
//     padding-bottom: 10rem;
//     padding-right: 10rem;
//     height: 62rem;
//     bottom: 100%;
//     left: 50%;
//     @include F_UR(14rem);
//     color: $c_f_text_dark;
//     background-color: $c_bg_block_grey;
//     line-height: 1.6;
//     text-align: left;
//     &:before {
//         position: absolute;
//         content: '';
//         width: 18rem;
//         height: 18rem;
//         left: 20rem;
//         top: 50%;
//         margin-top: -9rem;
//         background-size: contain;
//         background-position: center;
//     }
// }

// .bttn_submit {
//     position: relative;
//     [data-form-submit-error-text],
//     [data-form-submit-success-text] {
//         width: 219rem;
//         margin-left: -109.5rem;
//         margin-bottom: 15rem;
//         opacity: 1;
//     }
//     [data-form-submit-error-text] {
//         &:before {
//             //@extend %icon_note_white;
//         }
//     }
//     [data-form-submit-success-text] {
//         &:before {
//             //@extend %icon_ok;
//         }
//     }
// }

// .bttn_copy {
//     //@extend %icon_copy;
//     background-size: contain;
//     background-position: center;
//     margin-left: 14rem;
//     height: 22rem;
//     width: 22rem;
//     cursor: pointer;
//     position: relative;
//     .popup_result {
//         width: 192rem;
//         opacity: 0;
//         margin-left: -96rem;
//         margin-bottom: 18rem;
//         pointer-events: none;
//         &.show {
//             animation: showPopup;
//             animation-duration: 2s;
//         }
//         &.success {
//             &:before {
//                 //@extend %icon_ok;
//             }
//         }
//         &.error {
//             &:before {
//                 //@extend %icon_note_white;
//             }
//             color: $c_main_red;
//         }
//         @keyframes showPopup {
//             0% {
//                 opacity: 0;
//             }
//             10% {
//                 opacity: 1;
//             }
//             90% {
//                 opacity: 1;
//             }
//             100% {
//                 opacity: 0
//             }
//         }
//     }
// }

// .page-error,
// .page-success {
//     $p_e_height: 78rem;
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     width: 100%;
//     height: $p_e_height;
//     line-height: $p_e_height;
//     color: $c_f_text_dark;
//     text-align: center;
//     z-index: 11;
//     @include F_UR(18rem);
//     transform: translateY(-100%);
//     transition: transform 0.3s;
//     .visible {
//         transform: translateY(0);
//     }
//     span {
//         position: relative;
//         &:before {
//             content: '';
//             position: absolute;
//             width: 18rem;
//             height: 18rem;
//             left: -40rem;
//             top: 50%;
//             margin-top: -9rem;
//             background-size: contain;
//             background-position: center;
//         }
//     }
// }
//
// .page-error {
//     background-color: rgba(228, 37, 32, 0.25);
//     span {
//         &:before {
//             //@extend %icon_note_white;
//         }
//     }
// }
//
// .page-success {
//     background-color: rgba(39, 174, 96, 0.25);
//     span {
//         &:before {
//             //@extend %icon_ok;
//         }
//     }
// }

.wrapper_preloader-local {
    position: relative;
    width: 100%;
    height: 300rem;
}


// .trigger_menu {
//     display: none;
//     @extend %icon_menu;
//     cursor: pointer;
//     @include bp(tablet) {
//         display: block;
//         float: left;
//         width: 40rem;
//         height: 40rem;
//         margin-top: 35rem;
//         margin-right: 30rem;
//     }
// }
