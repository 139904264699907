$class_PH: 'payment-history';
@import "01_header_history.scss";
@import "02_note.scss";
@import "03_table_history.scss";
$class_INV: 'invoice';
@import "04_header_invoice.scss";
@import "05_table_invoice.scss";

.table_invoice,
.table_payment-history {
    .row_title,
    .row_content {
        height: 75rem;
        /*Change it!!!*/
        @include F_OSR(16rem);
    }
}
