.wrapper_page.wrapper_page_with_footer {
    padding-bottom: $footer_h + 40rem;
}

.wrapper_forms-login {
    .nav_tabs {
        font-size: 0;
        white-space: nowrap;

        a {
            &:nth-of-type(1) {
                .nav_tab-trig {
                    box-shadow: inset -2rem 0 4rem 0 rgba(0, 0, 0, 0.07);
                    background-image: url("../../images/decorative/bttn_login-min.png");
                }
            }

            &:nth-of-type(2) {
                .nav_tab-trig {
                    box-shadow: inset 2rem 0 4rem 0 rgba(0, 0, 0, 0.07);
                    background-image: url("../../images/decorative/bttn_signup-min.png");
                }
            }

            &.is-active .nav_tab-trig {
                box-shadow: inset 0 2rem 4rem 0 rgba(0, 0, 0, 0);
                color: $c_main_red;
                background-image: none;
            }
        }
    }

    .nav_tab-trig {
        $h: 48rem;
        display: inline-block;
        width: 50%;
        height: $h;
        line-height: $h;
        text-align: center;
        @include F_OSR(16rem);
        color: $c_f_text_dark;
        //background-image: linear-gradient(to top, rgba(0, 0, 0, 0.03), #{$c_bg_block_white});
    }

    .wrapper_captcha {
        text-align: center;
        margin-bottom: 40rem;
    }

    .inner_wrapper_captcha {
        display: inline-block;
    }
}

.tab_login,
.tab_register {
    display: block;

    input {
        color: $c_f_text_dark;
    }
}

.tab_login {
    padding-top: 64rem;
    padding-bottom: 32rem;
}

.tab_register {
    padding-bottom: 36rem;

    .nav_registr {
        text-align: center;
        margin-top: 23rem;
        margin-bottom: 23rem;
    }

    .item_nav {
        position: relative;
        display: inline-block;
        padding-left: 3rem;
        padding-right: 3rem;
        margin-left: 2rem;
        margin-right: 2rem;
        padding-top: 5rem;
        padding-bottom: 5rem;
        cursor: pointer;

        &.is-active {
            cursor: default;

            .icon_nav {
                background-color: $c_main_red;

                &:after {
                    display: none;
                }
            }
        }

        &.is-passed {
            .icon_nav {
                background-color: $c_bd_input_grey;
            }
        }
    }

    .icon_nav {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        background-color: transparent;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 4rem;
            height: 4rem;
            margin-left: -4rem;
            margin-top: -4rem;
            border-radius: 50%;
            border: 2rem solid $c_bd_input_grey;
        }
    }

    .wrapper_form-parts {}

    .tab_form {
        //display: none;
        display: block;

        &.is-active {
            display: block;
        }
    }

    .wrapper_checkbox {
        width: $form_elem;
        margin: -15rem auto 18rem;

        .elem_checkbox {
            left: 23rem;
        }

        .title_checkbox {
            padding-left: 58rem;
        }

        a {
            // @include F_OSR(inherit);
            @include F_OSSB(inherit);
            color: $c_main_red;
            cursor: pointer;
        }
    }
}
