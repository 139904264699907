.modal_card {
	.wrapper_modal-content {
		width: 464rem;
	}
}

.wrapper_card-modal-form {
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 25rem;
	padding-right: 20rem;
	padding-top: 30rem;
}

.wrapper_img_card-modal {
	margin-bottom: 42rem;
}

.form_card-modal {
	font-size: 0;
	width: 100%;
	margin-left: auto;
	margin-right: auto;

	.field_input {
		// padding-top: 15rem;
		// padding-bottom: 15rem;
	}

	.field_input.field_secured {
		padding-top: 12rem;
		padding-bottom: 12rem;
	}
}

.input_card-date {
	margin-right: 20rem;
}

.input_card-cvv,
.input_card-date {
	width: 100rem;
	height: 44rem;
	display: inline-block;
	vertical-align: top;
}

.wrapper_form-bottom {
	@extend %clear;

	.wrapper_checkbox {
		float: left;
		width: auto;
		margin-top: 0;
	}

	.elem_checkbox {
		left: 0;
	}

	.title_checkbox {
		width: 170rem;
		padding-left: 35rem;
	}
}

.bttn_save {
	@extend %bttn_general;
	width: 152rem;
	opacity: 1;
	cursor: pointer;
	float: right;
}
