@import "icons_action.scss";
@import "icons_decorative.scss";
@import "icons_menu.scss";
@import "icons_payments.scss";

%icon_plus {
    // background-image: url(../images/actions_icons/plus_icon.svg);
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20id%3D%22plus_icon-Layer_1%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%22%20xml%3Aspace%3D%22preserve%22%20viewBox%3D%220%200%2014%2014%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20width%3D%2232%22%20height%3D%2232%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%09.st0%7Bfill%3A%23E42520%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22m13%206h-5v-5c0-0.6-0.4-1-1-1s-1%200.4-1%201v5h-5c-0.6%200-1%200.4-1%201s0.4%201%201%201h5v5c0%200.6%200.4%201%201%201s1-0.4%201-1v-5h5c0.6%200%201-0.4%201-1s-0.4-1-1-1z%22%2F%3E%3C%2Fsvg%3E');
}

%icon_dots {
    //background-image: url(../images/action_icons/dots.svg);
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.001%209.36a2.64%202.64%200%201%200%200%205.282%202.64%202.64%200%200%200%200-5.282zm-8.4%200a2.64%202.64%200%201%200%200%205.282%202.64%202.64%200%200%200%200-5.282zm16.8%200a2.64%202.64%200%201%200%200%205.282%202.64%202.64%200%200%200%200-5.282z%22%20fill%3D%22%23e42520%22%2F%3E%3C%2Fsvg%3E');
}

%icon_warning {
    //background-image: url(../images/decorative/icon_warning.svg);
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='20' cy='20' r='18' stroke='%23E42042' stroke-width='4'/%3E%3Crect width='3.75' height='15.625' x='18.125' y='8.125' fill='%23E42042' rx='1.875'/%3E%3Crect width='3.75' height='3.75' x='18.125' y='26.25' fill='%23E42042' rx='1.875'/%3E%3C/g%3E%3C/svg%3E");
}

/* menu icons > */

%icon_menu {
    // background-image: url(../images/action_icons/burger_icon.svg);
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 22 14' style='enable-background:new 0 0 22 14;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0{fill:%23E42520;} %3C/style%3E%3Cg%3E%3Cpath class='st0' d='M21,2H1C0.4,2,0,1.6,0,1v0c0-0.6,0.4-1,1-1h20c0.6,0,1,0.4,1,1v0C22,1.6,21.6,2,21,2z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M21,14H1c-0.6,0-1-0.4-1-1v0c0-0.6,0.4-1,1-1h20c0.6,0,1,0.4,1,1v0C22,13.6,21.6,14,21,14z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M21,8H1C0.4,8,0,7.6,0,7v0c0-0.6,0.4-1,1-1h20c0.6,0,1,0.4,1,1v0C22,7.6,21.6,8,21,8z'/%3E%3C/g%3E%3C/svg%3E");
}

/* < menu icons */
