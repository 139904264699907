$full_w: 1061rem;

$main_dash_table_h: 212rem;


$cls_main_table: 'dashboard';

.block_content-#{$cls_main_table} {
    //margin-bottom: 40rem;

}


.text_disabled-#{$cls_main_table} {
    @include F_OSSB($fs24);
    color: $c_f_text_grey;
    line-height: 1.5;
    padding-top: ((80rem - 24rem * 1.5) / 2);
    padding-bottom: ((80rem - 24rem * 1.5) / 2);
    &:before {
        @extend %icon_warning;
    }
}

.wrapper_enabled-#{$cls_main_table} {
    position: relative;
    padding-right: $dash_elem_right_padding;
    @include F_OSSB($fs26);
    height: $main_dash_table_h;
    .button_toggle-details {
        top: $row_title_h / 2;
        transform: translateY(-50%);
    }
}



.col_status-payment-#{$cls_main_table} {
    position: absolute;
    top: 0;
    left: 0;
    height: $main_dash_table_h;
    width: 260rem;
    //padding-left: 50rem;
    //line-height: $main_dash_table_h;
    span {
        position: absolute;
        left: 50rem;
        top: 50%;
        transform: translateY(-50%);
       // display: inline-block;
        //vertical-align: middle;
        line-height: 1.23;
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: $main_dash_table_h;
        width: 28rem;

    }
    &.is-pending {
        &:before {
            background-color: $c_bg_block_grey;
        }
    }
    &.is-paid {
        &:before {
            background-color: $c_bg_block_lightgreen;
        }
    }
}



.table_#{$cls_main_table} {
    padding-left: 260rem;
    .row_content {
        height: 112rem;
    }
}

.cell {
    &_period-#{$cls_main_table} {
        padding-left: 30rem;
        width: percentage(492rem/$full_w);
    }
    &_total-#{$cls_main_table} {
        width: percentage(569rem/$full_w);
    }
}

.text {
    &_period-#{$cls_main_table} {
        @include F_OSSB($fs24);
        white-space: pre-wrap;
        color: $c_f_text_dark;
    }
    &_total-#{$cls_main_table} {
        @include F_OSB($fs28);
        color: $c_f_text_dark;
    }
}
