.freeze_page{
  display: flex;
  flex-direction: row-reverse;
  .main_block{
    width: 70%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .content_wrapper {
      width: 538px;
      .title-freeze_page {
        font-size: 40px;
        font-family: 'OpenSans-Regular', sans-serif;
        line-height: 1.2;
        margin-bottom: 30px;
      }
      .text-freeze_page {
        font-size: 18px;
        font-family: 'OpenSans-Regular', sans-serif;
        line-height: 1.56;
        font-weight: 400;
        & + .text-freeze_page{
          margin-top: 50px;
        }
      }
      .button-freeze_page{
        width: 245px;
        height: 46px;
        line-height: 46px;
        background-color: #ee403d;
        text-align: center;
        border-radius: 5px;
        text-transform: uppercase;
        color: $c_f_bttn_white;
        float: left;
        margin: 35px 0;
        font-size: 13px;
        font-family: 'OpenSans-Regular', sans-serif;
        font-weight: 700;
        letter-spacing: 1px;
        cursor: pointer;
        transition-duration: 0.3s;
        &:hover{
          background-color: #d7282d;
        }
      }
      .contact-freeze_page, .text_note{
        display: inline-block;
        font-size: 14px;
        color: $c_f_text_grey;
        font-family: 'OpenSans-Light', sans-serif;
        font-weight: 100;
        line-height: 1.43;
        .note{
          width: 42px;
          height: 16px;
          border-radius: 2px;
          color: $c_f_bttn_white;
          background-color: #f79924;
          text-align: center;
          line-height: 16px;
          text-transform: uppercase;
          font-size: 11px;
          display: inline-block;
          font-family: 'OpenSans-Regular', sans-serif;
        }
        .executive{
          color: $c_f_text_dark;
        }
        .link-freeze_page{
          text-decoration: underline;
        }
      }
      .list--freeze_page{
        margin-top: 10px;
        font-size: 14px;
        font-family: 'OpenSans-Light', sans-serif;
        line-height: 2;
        color: #4f5252;
      }
      .backup_cryptowallet{
        margin-top: 30px;
        .input_wrap{
          display: inline-block;
          &:first-child{
            margin-right: 26px;
          }
          label{
            color: #a2a0a0;
            font-size: 13px;
            font-family: 'OpenSans-Light', sans-serif;
            &:hover {
              cursor: pointer;
            }
          }
          input[type="text"],
          input[type="password"]{
            width: 293px;
            height: 34px;
            border-bottom: 2px solid #eff1f5;
            padding: 8px 0;
            display: block;
            font-size: 13px;
          }
        }
      }
    }
  }
  .right_side{
    font-family: 'OpenSans-Light', sans-serif;
    width: 30%;
    min-height: 100vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: $c_bg_main_grey url("../../images/decorative/exclamation_mark.svg") no-repeat center;
    .img_logo{
      width: 130px;
      margin-bottom: 70px;
    }
  }
}
.cryptowallet{
  .main_block{
    .content_wrapper{
      width: 614px;
    }
  }
}
@media (max-width: 900px){
  .freeze_page{
    flex-direction: column;
    .main_block{
      width: 100%;
      align-items: start;
      .content_wrapper{
        margin-top: 70px;
      }
    }
    .right_side{
      width: 100%;
      min-height: 57px;
      background: $c_bg_main_grey url("../../images/decorative/exclamation_mark_mob.svg") no-repeat 95%;
      align-items: center;
      justify-content: left;
      .img_logo{
        margin-bottom: 0;
        margin-left: 53px;
      }
    }
  }
  .ambivault{
    .main_block{
      justify-content: left;
      .content_wrapper{
        margin-left: 75px;
      }
    }
  }
}
@media (max-width: 650px){
  .freeze_page{
    .right_side{
      background-position: 97%;
      .img_logo{
        margin-left: 18px;
      }
    }
    .main_block{
      .content_wrapper{
        margin: 75px 21px 35px;
        width: 100%;
        .title-freeze_page {
          font-size: 27px;
          margin-bottom: 20px;
        }
        .backup_cryptowallet{
          .input_wrap{
            width: 100%;
            input[type="text"],
            input[type="password"]{
              width: 100%;
            }
            &:first-child{
              margin-right: 0;
              margin-bottom: 16px;
            }
          }
        }
        .text-freeze_page {
          & + .text-freeze_page{
            margin-top: 35px;
          }
        }
      }
    }
  }
}
@media (max-width: 480px){
  .freeze_page {
    .main_block {
      .content_wrapper {
        margin-top: 35px;
        .button-freeze_page {
          width: 100%;
        }
        .contact-freeze_page{
          margin-bottom: 20px;
        }
        .text_note{
          .note{
            display: block;
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}
