//
// Control
// ------------------------------
@import 'spinner';
@import 'mixins';

.Select {
    @include F_UR(14rem);
    position: relative; // disable some browser-specific behaviours that break the input

    input::-webkit-contacts-auto-fill-button,
    input::-webkit-credentials-auto-fill-button {
        display: none !important;
    }

    input::-ms-clear {
        display: none !important;
    }

    input::-ms-reveal {
        display: none !important;
    }
    // preferred box model
    &,
    & div,
    & input,
    & span {
        @include box-sizing(border-box);
    }
    // handle disabled state
    &.is-disabled {
        .Select-arrow-zone {
            cursor: default;
            pointer-events: none;
        }

        > .Select-control {
            background-color: $select-input-bg-disabled;

            &:hover {
                box-shadow: none;
            }
        }
    }

    &.is-searchable {
        &.is-open > .Select-control {
            cursor: text;
        }

        &.is-focused:not(.is-open) > .Select-control {
            cursor: text;
        }
    }

    &.is-open > .Select-control {
        background: $select-input-bg;

        .Select-arrow {
            transform: rotateX(180deg);
        }
    }

    &.is-focused > .Select-control {}

    &.is-focused:not(.is-open) > .Select-control {}

    &.has-value.is-clearable.Select--single > .Select-control .Select-value {}

    &.has-value.Select--single > .Select-control .Select-value,
    &.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value {
        .Select-value-label {
            color: $select-text-color;
        }

        a.Select-value-label {
            cursor: pointer;
            text-decoration: none;

            &:focus,
            &:hover {
                color: $select-link-hover-color;
                outline: none;
                text-decoration: underline;
            }

            &:focus {
                background: $select-input-bg-focus;
            }
        }
    }
    // fake-hide the input when the control is pseudo-focused
    &.has-value.is-pseudo-focused .Select-input {
        opacity: 0;
    }

    &.is-open .Select-arrow,
    .Select-arrow-zone:hover > .Select-arrow {
        border-top-color: $select-arrow-color-hover;
    }

    &.Select--rtl {
        direction: rtl;
        text-align: right;
    }
}
// base

.Select-control {
    background-color: $select-input-bg;
    color: $select-text-color;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: $form_field_h;
    cursor: pointer;
    border: solid 1rem $c_bd_input_grey;

    &:hover {}

    .Select-input:focus {
        outline: none;
        //background: $select-input-bg-focus;
    }
}
// placeholder

.Select--single > .Select-control .Select-value,
.Select-placeholder {
    bottom: 0;
    color: $select-input-placeholder;
    left: 0;
    line-height: $form_field_h + 2rem;
    padding-left: $select-padding-horizontal;
    padding-right: $select-padding-horizontal;
    position: absolute;
    right: 0;
    top: 0; // crop text
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
// the <input> element users type in

.Select-input {
    // inherits `display: inline-block` from "react-input-autosize"
    height: $select-input-internal-height;
    padding-left: $select-padding-horizontal;
    padding-right: $select-padding-horizontal;
    vertical-align: middle;

    > input {
        width: 100%;
        background: none transparent;
        border: 0 none;
        box-shadow: none;
        cursor: default;
        display: inline-block;
        font-family: inherit;
        font-size: inherit; // height: $select-input-internal-height;
        margin: 0;
        outline: none; // padding: 0;
        //line-height: 17rem;
        /* For IE 8 compatibility */
        //padding: (($select-input-internal-height - 14) / 2 - 2) 0 (($select-input-internal-height - 14) / 2 + 2);
        /* For IE 8 compatibility */
        -webkit-appearance: none;
        height: 100%;

        .is-focused & {
            cursor: text;
        }
    }
}
// fake input

.Select-control:not(.is-searchable) > .Select-input {
    outline: none;
}
// loading indicator

.Select-loading-zone {
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: $select-loading-size;
}

.Select-loading {
    @include Select-spinner($select-loading-size, $select-loading-color-bg, $select-loading-color);
    vertical-align: middle;
}
// the little cross that clears the field

.Select-clear-zone {
    @include animation(Select-animation-fadeIn 200ms);
    color: $select-clear-color;
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: $select-clear-width;

    &:hover {
        color: $select-clear-hover-color;
    }
}

.Select-clear {
    display: inline-block;
    font-size: $select-clear-size;
    line-height: 1;
}

.Select--multi .Select-clear-zone {
    width: $select-clear-width;
}

.Select--multi .Select-multi-value-wrapper {
    display: inline-block;
}

.Select .Select-aria-only {
    position: absolute;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    margin: -1rem;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    float: left;
}
// arrow indicator

.Select-arrow-zone {
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: ($select-arrow-width * 5);
    padding-right: $select-arrow-width;

    .Select--rtl & {
        padding-right: 0;
        padding-left: $select-arrow-width;
    }
}

.Select-arrow {
    @extend %icon_down;
    position: absolute;
    right: 15rem;
    top: 20rem;
    margin-top: -3rem;
    background-size: contain;
    background-position: center;
    width: 12rem;
    height: 6rem;
    transform: rotateX(0);
    //transition: transform $trans5;
    border: none;
}
// Animation
// ------------------------------
// fade in
@-webkit-keyframes Select-animation-fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes Select-animation-fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
