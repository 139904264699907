.modal_window {
    position: fixed;
    z-index: 100;
    left: $menu_w;
    top: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    height: 1rem;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: $trans3;

    &.is-visible {
        opacity: 1;
    }
}

.block_content-modal {
    padding-top: 30rem;
}

.wrapper_modal-content {
    background-color: $c_bg_block_white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 133rem;
    left: 0;
    right: 0;
    margin: auto;
    padding-left: 64rem;
    padding-right: 44rem;
    padding-bottom: 18rem;
    margin-bottom: 40rem;

    form {
        .text {
            @extend %input_text;
        }
    }

    .bttn_submit {
        @extend %bttn_common;
    }
}

.header_modal {
    height: 90rem;
    line-height: 90rem;
}

.bttn_close-modal {
    $side_size: 18rem;
    $right_offset: 23rem;
    $top_offset: 23rem;
    position: absolute;
    width: 40rem;
    height: 40rem;
    top: $top_offset - ((40rem - $side_size)/2);
    right: $right_offset - ((40rem - $side_size)/2);
    z-index: 1;
    cursor: pointer;

    &:after,
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: ((40rem - $side_size) / 2);
        left: ((40rem - $side_size) / 2);
        height: $side_size;
        width: $side_size;
        background-size: contain;
        background-position: center;
        transition: opacity $trans3;
    }

    &:before {
        @extend %icon_close;
        opacity: 1;
    }

    &:after {
        @extend %icon_close_active;
        opacity: 0;
    }

    &:hover {
        &:before {
            opacity: 0;
        }

        &:after {
            opacity: 1;
        }
    }
}

.modal-form_error {
    .modal-content-wrapper {
        width: 620rem;
        padding-left: 34rem;
        padding-right: 34rem;
        padding-bottom: 35rem;
        min-height: 352rem;
        color: $c_f_text_dark;
    }

    .small_title {
        padding-left: 32rem;
        @include F_UM(16rem);
        position: relative;
        margin-bottom: 20rem;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0; //@extend %icon_note;
            background-size: contain;
            background-position: center;
            height: 18rem;
            width: 18rem;
        }
    }

    .text {
        @include F_UL(16rem);
        line-height: 1.65;
    }
}

.modal-session_expired {
    .modal-content-wrapper {
        width: 530rem;
        padding: 111rem 27rem 48rem;
        min-height: 234rem;
        color: $c_f_text_dark;
        font-size: 16rem;
        text-align: center;
        top: 337rem;

        &:before {
            content: '';
            position: absolute;
            top: 42rem;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto; //@extend %icon_note;
            background-size: contain;
            background-position: center;
            height: 38rem;
            width: 38rem;
        }
    }

    .small_title {
        @include F_UM(22rem);
        margin-bottom: 15rem;
    }

    .text {
        @include F_UL(24rem);
        line-height: 1.5;
    }
}
