.item_logout,
.item_menu {
    cursor: pointer;

    a {
        display: block;
        height: 100%;
        width: 100%;
    }

    .item_inner {
        display: block;
        height: $menu_item_height;
        line-height: $menu_item_height;
        color: $c_f_text_grey;
        transition: color $trans3;
        padding-right: 15rem;

        &:hover {
            color: $c_f_text_white;

            span {
                &:before {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }
        }

        span {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            line-height: 1.5;

            &:after,
            &:before {
                content: '';
                @extend %vmid;
                background-size: contain;
                background-position: center;
                transition: opacity $trans3;
            }

            &:before {
                opacity: 1;
            }

            &:after {
                opacity: 0;
            }
        }
    }
}

.item_menu {
    .item_inner {
        padding-left: 65rem;
        @include F_OSR(16rem);
    }
}

.item_logout {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 11;
    @include F_OSR(14rem);
    background-color: $c_bg_block_dark;

    span {
        position: relative;
        padding-left: 27rem;

        &:after,
        &:before {
            left: 0;
            width: 18rem;
            height: 18rem;
        }

        &:before {
            @extend %icon_logout;
        }

        &:after {
            @extend %icon_logout-active;
        }
    }
}
@mixin menu_item($data_parameter, $icon_abstract, $size, $offset) {
    [data-menu-item=#{$data_parameter}] {
        .item_inner {
            span {
                &:after,
                &:before {
                    width: $size;
                    height: $size;
                    left: $offset;
                }

                &:before {
                    @extend %#{$icon_abstract};
                }

                &:after {
                    @extend %#{$icon_abstract}-active;
                }
            }
        }
    }

    [data-menu-item=#{$data_parameter}] {
        .is-active .item_inner {
            color: $c_f_text_white;

            span {
                &:before {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }
        }
    }
}
@include menu_item("dashboard", icon_dashboard, 26rem, -48rem);
@include menu_item("ambiVault", icon_ambiVault, 30rem, -50rem);
@include menu_item("cards", icon_payment_method, 32rem, -50rem);
@include menu_item("history", icon_history, 32rem, -50rem);
@include menu_item("profile", icon_profile, 26rem, -48rem);
