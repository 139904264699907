$full_w: 1114rem;
.cell_subject-#{$class_INV} {
	width: percentage(190rem/$full_w);
	padding-left: 18rem;
	padding-right: 18rem;
}

.cell_desc-#{$class_INV} {}

.cell_qty-#{$class_INV} {
	width: percentage(170rem/$full_w);
}

.cell_amount-#{$class_INV} {
	width: percentage(90rem/$full_w);
}

.text_subject-#{$class_INV} {
	@include F_UR(16rem);
}

.text_desc-#{$class_INV} {
	margin-top: 20rem;
	margin-bottom: 20rem;
	padding-right: 40rem;
	@include F_UL(16rem);
	line-height: 1.5;
	display: inline-block;
}

.text_qty-#{$class_INV} {
	@include F_UM(16rem);
}

.text_amount-#{$class_INV} {
	@include F_UM(16rem);
}
