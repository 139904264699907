$full_w: 1114rem;

.table_payment-history {
	.row_title {
		height: 75rem;
		@include F_OSR(16rem);
	}

	.row_content {
		color: $c_f_text_dark;
		height: 74rem;
	}
}

.block_content {}

.cell_date-#{$class_PH} {
	width: percentage(200rem/$full_w);
	padding-left: 18rem;
	padding-right: 12rem;
}

.cell_card-#{$class_PH} {
	width: percentage(260rem/$full_w);
	padding-right: 14rem;
}

.cell_status-#{$class_PH} {
	width: percentage(200rem/$full_w);
	padding-right: 20rem;

	&.is-paid {
		color: $c_f_text_dark;
	}

	&.is-pending {
		color: $c_f_text_grey;
	}

	&.is-failed {
		color: $c_main_red;
	}
}

.cell_amount-#{$class_PH} {
	//width: percentage(340rem/$full_w);
	padding-right: 24rem;
}

.cell_download-#{$class_PH},
.cell_print-#{$class_PH} {
	width: 53rem;
	text-align: center;
}

.cell_print-#{$class_PH} {}

.cell_download-#{$class_PH} {}

.text_card-#{$class_PH},
.text_date-#{$class_PH} {
	@include F_UR(16rem);
}

.text_amount-#{$class_PH},
.text_status-#{$class_PH} {
	@include F_UM(16rem);
}

.bttn_download-#{$class_PH},
.bttn_print-#{$class_PH} {
	display: inline-block;
	min-width: 35rem;
	width: 100%;
	height: 50rem;
	background-position: center center;
	cursor: pointer;
}

.bttn_print-#{$class_PH} {
	@extend %icon_print;
	background-size: 30rem 30rem;
}

.bttn_download-#{$class_PH} {
	@extend %icon_download;
	background-size: 25rem 25rem;
}
