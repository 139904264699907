.footer_main {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: $footer_h;
	line-height: $footer_h;
	background-color: $c_bg_block_grey;
	text-align: center;
	.link-foot,
	.credits {
		padding-left: 10rem;
		padding-right: 10rem;
		width: 206rem;
		display: inline-block;
		@include F_UL(14rem);
		vertical-align: middle;
	}
	.link-foot {
		color: $c_f_text_grey;
		cursor: pointer;
	}
	.credits {
		color: $c_f_text_dark;
	}
}
