$c_main_red: #e42520;
$c_main_yellow: #ffa600;
$c_main_green: #52a56c;
//
$c_f_text_grey: #989696;
$c_f_text_white: #f4f4f4;
$c_f_text_dark: #000000;
$c_f_bttn_white: #ffffff;
$c_f_bttn_notification: #15662e;

/**/

$c_bg_block_dark: #2d2d2d;
$c_bg_main_light: #fbfbfb;
$c_bg_block_white: #ffffff;
$c_bg_block_grey: #f4f4f4;
$c_bg_main_grey: #f3f6f9;
$c_bg_title_elem: #f9f9fc;
$c_bg_block_lightgreen: #eef7f2;
$c_bg_input_error: #434343;
$c_bg_error_red: #da4945;
$c_bg_line_grey: #ebebeb;


/**/

$c_bd_input_grey: #989696;
$c_bd_table_grey: #f4f4f4;
$c_bd_dashed_grey: #d0d3d5;
