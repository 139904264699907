$full_w: 1172rem;
.title_block-#{$class_INV} {
	width: percentage(425rem/$full_w);
}

.wrapper_total-#{$class_INV} {
	display: inline-block;
	min-width: percentage(425rem/$full_w);
	height: 100%;
	text-align: center;
	margin-right: 140rem;
	line-height: normal;
	vertical-align: middle;
}

.title_total-#{$class_INV} {
	@include F_UL(24rem);
	&:after {
		content: '\A'
	}
}

.num_total-#{$class_INV} {
	@include F_UM(24rem);
	&:after {
		content: '\A'
	}
}

.text_total-#{$class_INV} {
	@include F_UL(24rem);
}

.wrapper_bttns-#{$class_INV} {
	padding-left: 10rem;
	padding-right: 10rem;
	position: absolute;
	right: 0;
	bottom: 0;
}

.bttn_print-#{$class_INV},
.bttn_download-#{$class_INV} {
	margin-left: 10rem;
	margin-right: 10rem;
	width: 40rem;
	height: 40rem;
	display: inline-block;
	background-position: center;
	vertical-align: middle;
	cursor: pointer;
}

.bttn_print-#{$class_INV} {
	@extend %icon_print;
	background-size: 30rem 30rem;
}

.bttn_download-#{$class_INV} {
	@extend %icon_download;
	background-size: 25rem 25rem;
}
