//
// Select Menu
// ------------------------------
// wrapper around the menu

.Select-menu-outer {
    // Unfortunately, having both border-radius and allows scrolling using overflow defined on the same
    // element forces the browser to repaint on scroll.  However, if these definitions are split into an
    // outer and an inner element, the browser is able to optimize the scrolling behavior and does not
    // have to repaint on scroll.
    @include border-bottom-radius($select-input-border-radius);
    background-color: $select-input-bg;
    box-shadow: $select-menu-box-shadow;
    box-sizing: border-box;
    margin-top: -1rem;
    max-height: $select-menu-max-height;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: $select-menu-zindex;
    -webkit-overflow-scrolling: touch;
}
// wrapper

.Select-menu {
    max-height: $select-menu-max-height + 2rem;
    overflow-y: auto;
    border: 1rem solid $select-input-border-color;
    border-top-color: mix($select-input-bg, $select-input-border-color, 50%);
}
// options

.Select-input,
.Select-noresults,
.Select-option {
    box-sizing: border-box;
    color: $select-option-color;
    cursor: pointer;
    display: block; //padding: $select-padding-vertical $select-padding-horizontal;
    line-height: $form_field_h - 2rem;
    color: $c_f_text_dark;
    height: $form_field_h;
    padding-right: 30rem;
    padding-left: 10rem;
}

.Select-noresults,
.Select-option {
    background-color: $select-option-bg;
}

.Select-option {
    &:hover {
        color: $c_main_red;
    }

    &.is-selected {
        //display: none;
    }

    &.is-focused {}

    &.is-disabled {}
}
// no results

.Select-noresults {}
