$main_left_offset: 44rem;
$main_right_offset: 44rem;
$main_bottom_offset: 100rem;
$block_inner_top_offset: 32rem;
$desktop: 1500px;
$laptop: 1350px;
$tablet: 750px;
$mobile: 420px;

/**/

$menu_w: 220rem;
$menu_item_height: 62rem;
$form_elem: 406rem;
$desktop_full: 1440rem;
$footer_h: 64rem;
$form_field_h: 44rem;
$header_h: 130rem;
