.row_title {
    color: $c_f_text_grey; //@include F_OSR(24rem);

    > * {
        border-bottom: 1px solid $c_bd_table_grey;
    }
}

.row_content {
    > * {
        //border-bottom: 1px solid $c_bd_table_grey;
    }

    &-hovering {
        .inner_wrapper_row > * {
            border-bottom: 1px solid $c_bd_table_grey;
            transition: $trans3 border-color;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                height: 1px;
                top: -1px;
                background-color: transparent;
                transition: $trans3 background-color;
            }
        }

        &:hover {
            .inner_wrapper_row > * {
                border-color: $c_main_red;

                &:before {
                    background-color: $c_main_red;
                }
            }
        }
    }
}

.row_content,
.row_title {
    position: relative;
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.inner_wrapper_row {
    display: table-row;

    > * {
        display: table-cell;
        vertical-align: middle;
        word-wrap: break-word; //transition: $trans3 border-color;
    }
}
