$dash_elem_left_padding: 31rem;
$dash_elem_right_padding: 31rem;
$row_title_h: 100rem;
$fs20: 18rem; //20rem;
$fs24: 20rem; //24rem;
$fs26: 22rem; //26rem;
$fs28: 24rem; //28rem;

.block_content-AV,
.block_content-CW,
.block_content-dashboard {
    @extend %block_common;

    .row_title {
        height: 100rem;
        @include F_OSR($fs20);
    }
}

.block_content-CW {
    .header_content-elem-dashboard {
        &:before {
            @extend %icon_CW;
        }
    }
}

.block_content-AV {
    .header_content-elem-dashboard {
        &:before {
            @extend %icon_AV;
        }
    }
}

.block_content-AV,
.block_content-CW {
    margin-top: 40rem;

    .row_content {
        height: 92rem;
    }
}

.banner_elem-dashboard,
.table_elem-dashboard {
    padding-left: $dash_elem_left_padding;
    padding-right: $dash_elem_right_padding;
}

.header_content-elem-dashboard {
    background-color: $c_bg_title_elem;
    height: 100rem;
    line-height: 100rem;
    @include F_OSR(24rem);
}

.header_content-elem-dashboard,
.text_disabled-dashboard {
    position: relative;
    padding-left: 100rem;
    padding-right: 50rem;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -20rem;
        height: 40rem;
        width: 40rem;
        background-size: contain;
        background-position: center;
        left: $dash_elem_left_padding;
    }
}

.button_toggle-details {
    $vPad: 10rem;
    position: absolute;
    right: $dash_elem_right_padding; //height: $row_title_h;
    //line-height: $row_title_h;
    padding-top: $vPad;
    padding-bottom: $vPad;
    @include F_OSR($fs20);
    color: $c_main_red;
    padding-right: 28rem;
    z-index: 10;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        //top: $vPad + 14rem;
        top: 50%;
        right: 6rem;
        width: 12rem;
        height: 6rem;
        margin-top: -1rem;
        @extend %icon_down;
        //transition: transform $trans3;
    }

    &.is-hidden {
        &:before {
            transform: rotateX(0deg);
        }
    }

    &.is-visible {
        &:before {
            transform: rotateX(180deg);
        }
    }
}
