%icon_divider {
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 120 1' style='enable-background:new 0 0 120 1;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0{fill:url(%23SVGID_1_);} %3C/style%3E%3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='0' y1='0.5' x2='120' y2='0.5'%3E%3Cstop offset='0' style='stop-color:%23989696;stop-opacity:0'/%3E%3Cstop offset='0.5' style='stop-color:%23989696'/%3E%3Cstop offset='1' style='stop-color:%23989696;stop-opacity:0'/%3E%3C/linearGradient%3E%3Crect class='st0' width='120' height='1'/%3E%3C/svg%3E");
}

//Dashboard icons
%icon_AV {
    // background-image: url(../images/services_icons/icon_AV.svg);
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 60 60' style='enable-background:new 0 0 60 60;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0{fill:%23E42520;} %3C/style%3E%3Ctitle%3EAssetManager_icon%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg%3E%3Cpath class='st0' d='M52.9,5H7.1C4.8,5,3,6.8,3,9.1v41.9C3,53.2,4.8,55,7.1,55h45.9c2.2,0,4.1-1.8,4.1-4.1V9.1 C57,6.8,55.2,5,52.9,5z M7.1,7h45.9C54.1,7,55,7.9,55,9.1v2.6H5V9.1C5,7.9,5.9,7,7.1,7z M5,50.9V13.7h18V53H7.1 C5.9,53,5,52.1,5,50.9z M52.9,53H25V13.7h30v37.3C55,52.1,54.1,53,52.9,53z'/%3E%3Cpath class='st0' d='M10.7,28.5h6.2c1.5,0,2.7-1.2,2.7-2.7v-6.2c0-1.5-1.2-2.7-2.7-2.7h-6.2C9.2,17,8,18.2,8,19.7v6.2 C8,27.3,9.2,28.5,10.7,28.5z M10,19.7c0-0.4,0.3-0.7,0.7-0.7h6.2c0.4,0,0.7,0.3,0.7,0.7v6.2c0,0.4-0.3,0.7-0.7,0.7h-6.2 c-0.4,0-0.7-0.3-0.7-0.7V19.7z'/%3E%3Cpath class='st0' d='M9,33h10c0.6,0,1-0.4,1-1s-0.4-1-1-1H9c-0.6,0-1,0.4-1,1S8.4,33,9,33z'/%3E%3Cpath class='st0' d='M19,36H9c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1S19.6,36,19,36z'/%3E%3Cpath class='st0' d='M19,41H9c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1S19.6,41,19,41z'/%3E%3Cpath class='st0' d='M19,48H9c-0.6,0-1,0.4-1,1s0.4,1,1,1h10c0.6,0,1-0.4,1-1S19.6,48,19,48z'/%3E%3C/g%3E%3C/svg%3E");
}

%icon_CW {
    //background-image: url(../images/services_icons/icon_CW.svg);
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath stroke='%23E42520' stroke-width='2' d='M55.517 39C56.34 39 57 38.357 57 37.576V26.423c0-.78-.659-1.423-1.483-1.423h-7.319C44.218 25 41 28.14 41 32s3.218 7 7.198 7h7.319z'/%3E%3Cpath stroke='%23E42520' stroke-linecap='round' stroke-width='2' d='M51 14H6.789C5.248 14 4 12.657 4 11c0-1.656 1.248-3 2.789-3h41.783'/%3E%3Cpath stroke='%23E42520' stroke-linecap='round' stroke-width='2' d='M54.915 39v7.63c0 2.404-1.724 4.37-3.829 4.37H7.829C5.723 51 4 49.034 4 46.63V11M49 8c2.2 0 4 1.8 4 4v2M51 14c2.2 0 4 1.8 4 4v6'/%3E%3Ccircle cx='48' cy='32' r='3' fill='%23E42520'/%3E%3C/g%3E%3C/svg%3E");
}


%icon_check {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='15' viewBox='0 0 20 15'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23FFF' stroke-linecap='round' stroke-width='3' d='M1.968 6.734l5.97 5.97L18.473 2.166'/%3E%3C/svg%3E");
}

/*Error message icon*/
%icon_alert {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(1 1)'%3E%3Cpath fill='%23FFF' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M0 0h20v19H0z'/%3E%3Cpath stroke='%23DA4945' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M10 5v6'/%3E%3Ccircle cx='10' cy='14' r='1' fill='%23DA4945'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

%icon_check_green {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9' viewBox='0 0 12 9'%3e%3cpath fill='none' fill-rule='evenodd' stroke='%2352A56C' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M10.884 1.343L5.16 7.19l-3.33-3.402'/%3e%3c/svg%3e ");
}
