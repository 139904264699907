[data-page="error"] {
	.wrapper_error {
		width: 624rem;
		padding-top: 122rem;
		padding-bottom: 88rem;
		min-height: 324rem;
		text-align: center;
		position: relative;
		margin-left: auto;
		margin-right: auto;
		.bg-error-code {
			position: absolute;
			color: $c_bg_block_grey;
			@include F_UB(300rem);
			text-align: center;
			left: 0;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
		.text_error {
			position: relative;
			width: 496rem;
			font-size: 16rem;
			color: $c_f_text_dark;
			text-align: left;
			margin-left: auto;
			margin-right: auto;
			
			line-height: 1.65;
		}
		.bttn_back {
            @extend %bttn_common;
			position: relative;
			margin-top: 5rem;		
			display: inline-block;
			padding-left: 33rem;
			padding-right: 33rem;
		}
	}
}
