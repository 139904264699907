.wrapper_services-disabled {}

.banner_elem-dashboard {
    padding-top: 35rem;
    padding-bottom: 35rem;
}

.title_banner-dashboard {
    @include F_OSSB(24rem);
    color: $c_f_text_dark;
    padding-bottom: 25rem;
}

.text_banner-dashboard {
    @include F_OSR($fs20);
    color: $c_f_text_grey;
    line-height: 1.5;
    max-width: 920rem;
    margin-bottom: 20rem;
}

.link_button_banner {
    @extend %bttn_general;
    display: block;
    margin-top: 35rem;
    width: 262rem;

    &:hover {
        color: #ffffff;
    }
}

.wrapper_banner-info-fee {
    border-top: 1px solid $c_bd_table_grey;
    padding-top: 20rem;
    line-height: 1.5;

    .text_fee {
        //@include F_OSR(20rem);
        @include F_OSSB($fs20);
    }

    .price_banner-fee {
        //@include F_OSB(20rem);
        @include F_OSR($fs20);
    }
}

.wrapper_banner-note-fee {
    padding-top: 20rem;
}
