@import "header.scss";
@import "login.scss";
@import "restore_pass.scss";
@import "error.scss";
@import "footer.scss";

.wrapper_forms-forgot-pass,
.wrapper_forms-login,
.wrapper_forms-new-pass {
    @extend %block_shadow;
    max-width: 534rem;
    margin-left: auto;
    margin-right: auto;
    background-color: $c_bg_block_white;

    .group_field {
        margin-left: auto;
        margin-right: auto;
    }

    .bttn_submit {
        @extend %bttn_common;
        cursor: pointer;
    }

    .wrapper_text_link-entrance {
        margin-top: 30rem;
        text-align: center;

        > a {
            display: inline-block;
        }
    }

    .text_link-entrance {
        display: inline-block;
        color: $c_main_red;
        @include F_OSR(16rem);
        cursor: pointer;
    }
}
