$class_SC: "single-card";
$single_item_w: 336rem;
$single_item_h: 208rem;
$border_radius: 8rem;

.wrapper_cards {
    padding-bottom: 80rem;
}

.inner_wrapper_cards {
    @extend %clear;
    margin-left: -15rem;
    margin-top: -30rem;
}

.wrapper_item-card {
    float: left;
    padding: 15rem;
    width: $single_item_w + 30rem;
    height: $single_item_h + 30rem;
    transition: $trans5;
    opacity: 1;

    &.is-removed {
        overflow: hidden;
        width: 0;
        opacity: 0;
        padding-left: 0;
        padding-right: 0;
    }

    &.is-primary {
        .wrapper_manage-#{$class_SC} {
            display: none;
        }

        .text_status-#{$class_SC} {
            display: inline-block;
        }
    }
}

.item_#{$class_SC},
.item_add-card {
    width: $single_item_w;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}
/* Default styles for cards */
.img_bg_single-card {
    background-image: linear-gradient(to left, #2ba5ff, #1c78d2);
}

.icon_single-card-payment-system {
    text-align: left;
    background-color: transparent;
    @include F_OSR(20rem);
    color: transparentize($c_f_bttn_white, .5);
    white-space: nowrap;
}

%hide_default_icon_placeholder {
    background-color: $c_bg_block_white;
    color: transparent;
    font-size: 0;
}
/* Default styles for cards */
.item_#{$class_SC} {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.18);

    &-visa {
        .img_bg_single-card {
            background-image: linear-gradient(to left, #6972f0, #555ed4);
        }

        .icon_single-card-payment-system {
            @extend %hide_default_icon_placeholder;

            &:before {
                @extend %icon_visa;
            }
        }
    }

    &-masterCard {
        .img_bg_single-card {
            background-image: linear-gradient(to left, #f48689, #d44a9d);
        }

        .icon_single-card-payment-system {
            @extend %hide_default_icon_placeholder;

            &:before {
                @extend %icon_mastercard;
            }
        }
    }

    &-paypal {
        .img_bg_single-card {
            background-image: linear-gradient(to left, #2ba5ff, #1c78d2);
        }

        .icon_single-card-payment-system {
            @extend %hide_default_icon_placeholder;

            &:before {
                background-image: url("../../images/decorative/payment_pay_pal-min.png");
                @include pixelRatio(2) {
                    background-image: url("../../images/decorative/payment_pay_pal@2-min.png");
                }
            }
        }
    }

    &-americanExpress {
        .img_bg_single-card {
            background-image: linear-gradient(to left, #27d2cf, #24a8ba);
        }

        .icon_single-card-payment-system {
            @extend %hide_default_icon_placeholder;

            &:before {
                @extend %icon_american-express;
            }
        }
    }

    &-dinersClub {
        .icon_single-card-payment-system {
            @extend %hide_default_icon_placeholder;

            &:before {
                @extend %icon_diners-club;
            }
        }
    }

    &-jCB {
        .icon_single-card-payment-system {
            @extend %hide_default_icon_placeholder;

            &:before {
                @extend %icon_jcb;
            }
        }
    }

    &-discover {
        .icon_single-card-payment-system {
            @extend %hide_default_icon_placeholder;

            &:before {
                @extend %icon_discover;
            }
        }
    }

    /*&-citibank {
        .icon_single-card-payment-system {
            @extend %hide_default_icon_placeholder;

            &:before {
                @extend %icon_citibank;
            }
        }
    }

    &-maestro {
        .icon_single-card-payment-system {
            @extend %hide_default_icon_placeholder;

            &:before {
                @extend %icon_maestro;
            }
        }
    }*/

    &-unionPay {
        .icon_single-card-payment-system {
            @extend %hide_default_icon_placeholder;

            &:before {
                @extend %icon_union-pay;
            }
        }
    }
}

.icon_single-card-payment-system {
    width: 60rem;
    height: 37rem;
    margin-bottom: 18rem;
    position: relative;
    //background-color: $c_bg_block_white;
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-position: center;
        background-size: contain;
        width: 60rem;
        height: 37rem;
    }
}

.img_bg_#{$class_SC} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.img_bg_#{$class_SC},
.item_#{$class_SC},
.item_add-card {
    border-radius: $border_radius;
}

.block_content_#{$class_SC} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 53rem 36rem 25rem;
    line-height: 1.5;
}

.number_#{$class_SC} {
    @include F_OSR(18rem);
    letter-spacing: 3.5rem;
    color: $c_f_bttn_white;
    line-height: 1;
    margin-bottom: 25rem;
}

.wrapper_single-card-data {
    position: relative;
    padding-top: 2rem;
}

.name_#{$class_SC} {
    @include F_OSR(16rem);
    color: transparentize($c_f_bttn_white, .5);
    line-height: 1.5;
    width: 200rem;
    word-break: break-word;
    height: 48rem;
    overflow: hidden;
}

.date_#{$class_SC} {
    position: absolute;
    right: 0;
    top: 0;
    @include F_OSR(16rem);
    color: $c_f_bttn_white;
    line-height: 27rem;
}

.item_add-card {
    @extend %icon_border-dashed;
    background-size: contain;
    //border: 2px dashed $c_bd_dashed_grey;
    padding: 121rem 17rem 17rem;
    cursor: pointer;
}

.text_add-card {
    position: relative;
    text-align: center;
    @include F_OSR(16rem);
    color: $c_main_red;

    &:before {
        content: '';
        position: absolute;
        top: -47rem;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 32rem;
        height: 32rem;
        @extend %icon_add-card;
        background-size: contain;
    }
}
