.block_menu-main {
    position: fixed;
    overflow: hidden;
    left: 0;
    top: 0;
    height: 100vh;
    @media print {
        display: none;
    }
    @include bp(tablet) {
        transform: translateX(-100%);
        transition: $trans5;
        z-index: 20;

        &.is-visible {
            transform: translateX(0);
        }
    }
}

.shim_menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 11;
    opacity: 0;
    transition: $trans5;

    &.is-visible {
        opacity: 1;
    }
}

.inner_wrapper_menu {
    position: relative;
    width: $menu_w;
    height: 100vh;
    padding-bottom: $menu_item_height;
    background-color: $c_bg_block_dark;
    z-index: 10;

    header {
        height: 110rem;
        padding-top: 40rem;
        padding-left: 17rem;
        padding-right: 17rem;
    }

    .img_wrapper_logo-main {
        display: block;
        position: relative;
        width: 130rem;
        //height: 26rem;
    }
}
@import "__menu_01_items.scss";
