.header_main {
	$h: 117rem;
	$logo_w: 158rem;
	$logo_w_vol: percentage($logo_w / $desktop_full);
	position: relative;
	height: $h;
	text-align: center;

	.img_logo {
		position: absolute;
		top: 22rem;
		left: 35rem;
		width: $logo_w_vol;
		min-width: $logo_w;
		height: 40.3rem;
		cursor: pointer;
	}

	.subtitle_block,
	.title_block {
		padding-left: $logo_w_vol + 3%;
		padding-right: $logo_w_vol + 3%;
	}

	.title_block {
		padding-top: 35rem;
		padding-bottom: 7rem;
	}

	.subtitle_block {
		@include F_OSR(16rem);
		color: $c_f_text_dark;
		line-height: 1.5;
	}
}
