.wrapper_note-#{$class_PH} {
	padding-bottom: 30rem;
}

.content_note-#{$class_PH} {
	@extend %block_common;
	position: relative;
	padding-left: 181rem;
	padding-right: 252rem;
	padding-top: 20rem;
	padding-bottom: 20rem;
	min-height: 140rem;
	line-height: 100rem;
	&:before {
		content: '';
		position: absolute;
		width: 64rem;
		height: 64rem;
		@extend %icon_warning;
		background-position: center;
		background-size: contain;
		top: 50%;
		transform: translateY(-50%);
		left: 59rem;
	}
}

.text_note-#{$class_PH} {
	display: inline-block;
	@include F_UL(16rem);
	line-height: 1.5;
	vertical-align: middle;
}

.bttn_pay-#{$class_PH} {
	@extend %bttn_common;
	width: 170rem;
	height: 64rem;
	line-height: 64rem;
	position: absolute;
	right: 33rem;
	top: 50%;
	transform: translateY(-50%);
}
