$class_PF: 'profile';

.block_content-#{$class_PF} {
    padding-top: 53rem;
    width: 915rem;
    padding-bottom: 10rem;
}

.form-#{$class_PF} {
    margin-left: -20rem;
    margin-right: -20rem;
}

.wrapper_form-top-#{$class_PF} {}

.wrapper_form-bottom-#{$class_PF} {
    padding-top: 58rem;

    &:before {
        @extend %icon_divider;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-size: 100% auto;
        background-position: center;
    }
}

.wrapper_form-bottom-#{$class_PF},
.wrapper_form-top-#{$class_PF} {
    @extend %clear;

    .group_field {
        margin-left: 20rem;
        margin-right: 20rem;
        width: 100%;
        float: left;
        height: 44rem;
    }
}
// .select-#{$class_PF} {
//     position: absolute;
//     background-color: $c_bg_block_white;
//     z-index: 10;
// }

.wrapper_buttons-profile {
    font-size: 0;
    text-align: center;
}

.bttn_reset-#{$class_PF},
.bttn_submit-#{$class_PF} {
    @extend %bttn_general;
    margin-left: 20rem;
    margin-right: 20rem;
    display: inline-block;
    margin-bottom: 20rem;

    &.is-invisible {
        opacity: 0;
        cursor: default;
    }
}
