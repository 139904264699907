%icon_close_notification {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13'%3e%3cg fill='none' fill-rule='evenodd' stroke='%2315662E' stroke-linecap='round' stroke-width='2'%3e%3cpath d='M1 1l11 11M12 1L1 12'/%3e%3c/g%3e%3c/svg%3e ");
}

%icon_close_error {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23830B07' stroke-linecap='round' stroke-width='2'%3E%3Cpath d='M1 1l11 11M12 1L1 12'/%3E%3C/g%3E%3C/svg%3E");
}
/*Modal window close*/
%icon_close {
    // background-image: url(../images/actions_icons/close_icon.svg);
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20id%3D%22close_icon-Layer_1%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%22%20xml%3Aspace%3D%22preserve%22%20viewBox%3D%220%200%2014%2014%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20width%3D%2232%22%20height%3D%2232%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%09.st0%7Bfill%3A%23969898%3B%7D%3C%2Fstyle%3E%3Ctitle%3EGroup%3C%2Ftitle%3E%3Cdesc%3ECreated%20using%20Figma%3C%2Fdesc%3E%3Cpath%20class%3D%22st0%22%20d%3D%22m8.5%207l5.2-5.2c0.4-0.4%200.4-1.1%200-1.5s-1.1-0.4-1.5%200l-5.2%205.2-5.2-5.2c-0.4-0.4-1.1-0.4-1.5%200s-0.4%201.1%200%201.5l5.2%205.2-5.2%205.2c-0.4%200.4-0.4%201.1%200%201.5s1.1%200.4%201.5%200l5.2-5.2%205.2%205.2c0.4%200.4%201.1%200.4%201.5%200s0.4-1.1%200-1.5l-5.2-5.2z%22%2F%3E%3C%2Fsvg%3E");
}

%icon_close_active {
    // background-image: url(../images/actions_icons/close_icon_active.svg);
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20id%3D%22close_icon_active-Layer_1%22%20style%3D%22enable-background%3Anew%200%200%2014%2014%22%20xml%3Aspace%3D%22preserve%22%20viewBox%3D%220%200%2014%2014%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20width%3D%2232%22%20height%3D%2232%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%09.st0%7Bfill%3A%23E42520%3B%7D%3C%2Fstyle%3E%3Ctitle%3EGroup%3C%2Ftitle%3E%3Cdesc%3ECreated%20using%20Figma%3C%2Fdesc%3E%3Cpath%20class%3D%22st0%22%20d%3D%22m8.5%207l5.2-5.2c0.4-0.4%200.4-1.1%200-1.5s-1.1-0.4-1.5%200l-5.2%205.2-5.2-5.2c-0.4-0.4-1.1-0.4-1.5%200s-0.4%201.1%200%201.5l5.2%205.2-5.2%205.2c-0.4%200.4-0.4%201.1%200%201.5s1.1%200.4%201.5%200l5.2-5.2%205.2%205.2c0.4%200.4%201.1%200.4%201.5%200s0.4-1.1%200-1.5l-5.2-5.2z%22%2F%3E%3C%2Fsvg%3E");
}
/* Checkbox icon */
%icon_checkbox {
    // background-image: url(../images/actions_icons/active_chekbox.svg);
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20id%3D%22active_chekbox-Layer_1%22%20style%3D%22enable-background%3Anew%200%200%2018%2018%22%20xml%3Aspace%3D%22preserve%22%20viewBox%3D%220%200%2018%2018%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20width%3D%2232%22%20height%3D%2232%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%09.st0%7Bfill%3A%23E42520%3B%7D%3C%2Fstyle%3E%3Cg%20id%3D%22active_chekbox-Checkbox%22%3E%09%3Cg%20id%3D%22active_chekbox-Subtract%22%3E%09%09%3Cg%20transform%3D%22translate%28-375%206869%29%22%3E%09%09%09%3Cpath%20id%3D%22active_chekbox-path1_fill%22%20d%3D%22m3%200c-1.7%200-3%201.3-3%203v12c0%201.7%201.3%203%203%203h12c1.7%200%203-1.3%203-3v-12c0-1.7-1.3-3-3-3h-12zm12.3%204.6c0.4%200.3%200.5%200.9%200.1%201.3l-6.1%207.3c-0.1%200.1-0.2%200.2-0.4%200.3-0.4%200.2-0.8%200.1-1.1-0.2l-4.2-4.6c-0.4-0.4-0.3-1%200.1-1.3%200.4-0.4%201-0.3%201.4%200.1l3.5%203.8%205.4-6.5c0.2-0.5%200.9-0.5%201.3-0.2z%22%20class%3D%22st0%22%2F%3E%09%09%3C%2Fg%3E%09%3C%2Fg%3E%3C%2Fg%3E%3Cpath%20class%3D%22st0%22%20d%3D%22m15%200h-12c-1.7%200-3%201.3-3%203v12c0%201.7%201.3%203%203%203h12c1.7%200%203-1.3%203-3v-12c0-1.7-1.3-3-3-3zm0.4%205.9l-6.1%207.3c-0.1%200.1-0.2%200.2-0.4%200.3-0.4%200.2-0.8%200.1-1.1-0.2l-4.2-4.6c-0.4-0.4-0.3-1%200.1-1.3%200.4-0.4%201-0.3%201.4%200.1l3.5%203.8%205.4-6.5c0.3-0.4%200.9-0.5%201.3-0.1%200.4%200.2%200.4%200.8%200.1%201.2z%22%2F%3E%3C%2Fsvg%3E");
}

%icon_download {
    // background-image: url(../images/actions_icons/download_icon.svg);
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20id%3D%22download_icon-Layer_1%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2232%22%20height%3D%2232%22%3E%3Cstyle%3E.st0%7Bfill%3A%23e42520%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M26%2029H6c-1.7%200-3-1.3-3-2.8V24c0-.6.4-1%201-1s1%20.4%201%201v2.2c0%20.4.5.8%201%20.8h20c.6%200%201-.4%201-.8V24c0-.6.4-1%201-1s1%20.4%201%201v2.2c0%201.5-1.4%202.8-3%202.8zM20.5%2014.7c-.4-.3-1.1-.2-1.4.2L16%2019.3%2012.9%2015c-.3-.4-.9-.5-1.4-.2-.4.3-.5.9-.2%201.4l3.9%205.4h1.6l3.9-5.4c.4-.5.3-1.1-.2-1.5z%22%2F%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M16%2022c-.6%200-1-.4-1-1V6c0-.6.4-1%201-1s1%20.4%201%201v15c0%20.5-.4%201-1%201z%22%2F%3E%3C%2Fsvg%3E");
}

%icon_print {
    // background-image: url(../images/actions_icons/print_icon.svg);
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20id%3D%22print_icon-Layer_1%22%20viewBox%3D%220%200%2032%2032%22%20width%3D%2232%22%20height%3D%2232%22%3E%3Cstyle%3E.st0%7Bfill%3A%23e42520%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M26.5%207H26V4.5c0-.8-.7-1.5-1.6-1.5H7.6C6.7%203%206%203.7%206%204.5V7h-.5C3.6%207%202%208.5%202%2010.3v11.4C2%2023.5%203.6%2025%205.5%2025h1L6%2027.7c-.1.5%200%201.1.4%201.5.5.5%201.1.8%201.8.8h15.6c.7%200%201.4-.3%201.8-.8.4-.4.5-1%20.4-1.5l-.4-2.7h.9c1.9%200%203.5-1.5%203.5-3.3V10.3C30%208.5%2028.4%207%2026.5%207zM8%205h16v2H8V5zm15.9%2023H8l1.3-7h13.5l1.3%207h-.2zm4.1-6.3c0%20.7-.7%201.3-1.5%201.3h-1.3l-.4-2h.2c.6%200%201-.4%201-1s-.4-1-1-1H7c-.6%200-1%20.4-1%201s.4%201%201%201h.3l-.4%202H5.5c-.8%200-1.5-.6-1.5-1.3V10.3C4%209.6%204.7%209%205.5%209H26.5c.8%200%201.5.6%201.5%201.3v11.4z%22%2F%3E%3Ccircle%20class%3D%22st0%22%20cx%3D%2225%22%20cy%3D%2212%22%20r%3D%221%22%2F%3E%3Ccircle%20class%3D%22st0%22%20cx%3D%2222%22%20cy%3D%2212%22%20r%3D%221%22%2F%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M19.9%2022H11c-.6%200-1%20.4-1%201s.4%201%201%201h8.9c.6%200%201-.4%201-1s-.5-1-1-1zM16.1%2025H11c-.6%200-1%20.4-1%201s.4%201%201%201h5.1c.6%200%201-.4%201-1s-.5-1-1-1z%22%2F%3E%3C%2Fsvg%3E");
}
/*Dashboard hide/show button*/
%icon_down {
    // background-image: url(../images/actions_icons/arrow_down.svg);
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20height%3D%226%22%20width%3D%2212%22%20version%3D%221.1%22%20viewBox%3D%220%200%2012%206%22%3E%3Ctitle%3EPolygon%3C%2Ftitle%3E%3Cdesc%3ECreated%20using%20Figma%3C%2Fdesc%3E%3Cg%20id%3D%22dropdown_arrow-Canvas%22%20transform%3D%22translate%282845%20-779%29%22%3E%3Cg%20id%3D%22dropdown_arrow-Polygon%22%3E%3Cuse%20xlink%3Ahref%3D%22%23dropdown_arrow-path0_fill%22%20transform%3D%22matrix%28-1%201.3878e-16%20-1.3878e-16%20-1%20-2833%20785%29%22%20fill%3D%22%23E42520%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3Cdefs%3E%3Cpath%20id%3D%22dropdown_arrow-path0_fill%22%20d%3D%22m5.2929%200.70711c0.3905-0.39053%201.0237-0.39053%201.4142%200l3.5859%203.5858c0.63%200.63%200.184%201.7071-0.7072%201.7071h-7.1716c-0.8909%200-1.3371-1.0771-0.7071-1.7071l3.5858-3.5858z%22%2F%3E%3C%2Fdefs%3E%3C%2Fsvg%3E");
}
