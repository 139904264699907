.block_manage-#{$class_SC} {
    position: absolute;
    top: 10rem;
    right: 0;
    left: 0;
    height: 33rem;
    line-height: 33rem;
}

.text_status-#{$class_SC} {
    //display: none;
    //right: 46rem;
    right: 15rem;
    padding-right: 12rem;
    padding-left: 12rem;
    @include F_OSB(14rem);
    color: $c_f_bttn_white;
    line-height: 33rem;
    position: absolute;
}

.wrapper_manage-#{$class_SC} {
    position: absolute;
    right: 12rem;
    top: -2rem;
}

.bttn_manage-#{$class_SC} {
    width: 40rem;
    height: 40rem;
    cursor: pointer;
    z-index: 2;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 18rem;
        height: 4rem;
        background-position: center;
        background-size: contain;
        @extend %icon_menu-dots;
    }
}

.popup_manage-#{$class_SC} {
    display: none;
    position: absolute;
    bottom: 7rem;
    left: 50%;
    transform: translate(-50%, 100%);
    min-width: 160rem;
    padding-top: 20rem;
    padding-bottom: 17rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    line-height: 30rem;
    background-color: $c_bg_block_white;
    @include F_OSR(14rem);
    text-align: center;
    z-index: 1;

    &.is-active {
        display: block;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        width: 0;
        height: 0;
        border-width: 6rem;
        border-style: solid;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        border-bottom-color: $c_bg_block_white;
    }

    > * {
        padding-left: 15rem;
        padding-right: 15rem;
        transition: $trans3;
        color: $c_f_text_dark;
        cursor: pointer;
        background-color: $c_bg_block_white;

        &:hover {
            background-color: $c_bg_main_grey;
        }
    }
}

.bttn_primary-#{$class_SC} {}

.bttn_delete-#{$class_SC} {
    color: $c_main_red;
}
