.wrapper_checkbox {
    position: relative;

    input[type="checkbox"] {
        display: none;
    }
}

.elem_checkbox {
    position: absolute;
    top: 50%;
    border: 2px solid $c_bd_input_grey;
    width: 18rem;
    height: 18rem;
    background: $c_bg_block_white;
    border-radius: 4rem;
    margin-top: -9rem;
    cursor: pointer;
}

.title_checkbox {
    display: inline-block;
    @include F_OSR(14rem);
}

input:checked + .elem_checkbox {
    background-origin: border-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @extend %icon_checkbox;
    border-color: $c_main_red;
}
