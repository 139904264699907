.wrapper_forms-forgot-pass {
    margin-top: 32rem;
    padding-top: 65rem;
    padding-bottom: 30rem;
}

.wrapper_text_link-entrance {}

.text_link-entrance {}

.wrapper_forms-new-pass {
    padding-top: 65rem;
    padding-bottom: 58rem;

    .bttn_submit {
        width: 306rem;
        max-width: 80%;
    }
}

.wrapper_decor-pass-strength {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    max-width: 406rem;
    margin: -31rem auto 50rem;

    &.is-simple {
        .decor-pass-strength {
            &:nth-child(2),
            &:nth-child(3) {
                background-color: $c_bg_line_grey;
            }
            background-color: $c_main_red;
        }
    }

    &.is-normal {
        .decor-pass-strength {
            &:nth-child(3) {
                background-color: $c_bg_line_grey;
            }
            background-color: $c_main_yellow;
        }
    }

    &.is-strong {
        .decor-pass-strength {
            background-color: $c_main_green;
        }
    }
}

.decor-pass-strength {
    width: 133rem;
    height: 4rem;
    border-radius: 2rem;
    background-color: $c_bg_line_grey;
    transition: background-color $trans3;
}

.block_recovery-pass-submitted {
    max-width: 730rem;
    padding-left: 20rem;
    padding-right: 20rem;
    margin-left: auto;
    margin-right: auto;

    .icon_check {
        @extend %icon_check;
        background-size: 20rem 15rem;
        background-color: $c_main_green;
        background-position: center;
        border-radius: 50%;
        width: 50rem;
        height: 50rem;
        margin-left: auto;
        margin-right: auto;
    }
}

.text_recovery-pass-submitted {
    padding-top: 33rem;
    padding-bottom: 33rem;
    @include F_OSR(34rem);
    line-height: 1.35;
    color: $c_f_text_dark;
    text-align: center;
}
