$path: './';


@font-face {
    font-family: 'OpenSans-Light';
    src: local('OpenSans-Light'),
    local('OpenSans Light'),
    url($path + 'OpenSans-Light.woff2') format('woff2'),
    url($path + 'OpenSans-Light.woff') format('woff'),
    url($path + 'OpenSans-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    unicode-range: U+0020-007F;
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: local('OpenSans-Regular'),
    local('OpenSans Regular'),
    url($path + 'OpenSans-Regular.woff2') format('woff2'),
    url($path + 'OpenSans-Regular.woff') format('woff'),
    url($path + 'OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    unicode-range: U+0020-007F;
}

@font-face {
    font-family: 'OpenSans-SemiBold';
    src: local('OpenSans-SemiBold'),
    local('OpenSans SemiBold'),
    url($path + 'OpenSans-SemiBold.woff2') format('woff2'),
    url($path + 'OpenSans-SemiBold.woff') format('woff'),
    url($path + 'OpenSans-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    unicode-range: U+0020-007F;
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: local('OpenSans-Bold'),
    local('OpenSans Bold'),
    url($path + 'OpenSans-Bold.woff2') format('woff2'),
    url($path + 'OpenSans-Bold.woff') format('woff'),
    url($path + 'OpenSans-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    unicode-range: U+0020-007F;
}



@font-face {
    font-family: 'OpenSans-ExtraBold';
    src: local('OpenSans-ExtraBold'),
    local('OpenSans ExtraBold'),
    url($path + 'OpenSans-ExtraBold.woff2') format('woff2'),
    url($path + 'OpenSans-ExtraBold.woff') format('woff'),
    url($path + 'OpenSans-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    unicode-range: U+0020-007F;
}

html,
body {
    font-family: 'OpenSans-Regular', 'OpenSans-Light', 'OpenSans-SemiBold', 'OpenSans-Bold', 'OpenSans-ExtraBold', sans-serif;
}
