%icon_add-card {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='16' cy='16' r='15' stroke='%23E42520' stroke-width='2'/%3E%3Cpath fill='%23E42520' d='M17 15h5a1 1 0 0 1 0 2h-5v5a1 1 0 0 1-2 0v-5h-5a1 1 0 1 1 0-2h5v-5a1 1 0 1 1 2 0v5z'/%3E%3C/g%3E%3C/svg%3E%0A");
}

%icon_border-dashed {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='336' height='208' viewBox='0 0 336 208'%3E%3Cdefs%3E%3Cpath id='a' d='M612 338h320a8 8 0 0 1 8 8v192a8 8 0 0 1-8 8H612a8 8 0 0 1-8-8V346a8 8 0 0 1 8-8z'/%3E%3Cmask id='b' width='336' height='208' x='0' y='0' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3C/defs%3E%3Cuse fill='none' fill-rule='evenodd' stroke='%23D0D3D5' stroke-dasharray='8,8' stroke-width='4' mask='url%28%23b%29' transform='translate%28-604 -338%29' xlink:href='%23a'/%3E%3C/svg%3E%0A");
}

%icon_menu-dots {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='4' viewBox='0 0 18 4'%3E%3Cg fill='%23FFF' fill-rule='evenodd' transform='rotate%28-90 2 2%29'%3E%3Ccircle cx='2' cy='2' r='2'/%3E%3Ccircle cx='2' cy='9' r='2'/%3E%3Ccircle cx='2' cy='16' r='2'/%3E%3C/g%3E%3C/svg%3E%0A");
}
