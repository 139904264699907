.header_block {
	@extend %clear;
}

.title_block-#{$class_PH} {}

.wrapper_filter-#{$class_PH} {
	float: right;
	height: 100%;
}

.label_filter-#{$class_PH} {}

.label_start-#{$class_PH} {
	padding-left: 12rem;
	padding-right: 2rem;
}

.label_end-#{$class_PH} {
	padding-left: 12rem;
	padding-right: 12rem;
}

.field_filter-#{$class_PH} {
	height: 44rem;
	border: 1px solid $c_bd_input_grey;
	background-color: $c_bg_block_white;
	text-align: center;
	width: 102rem;
	margin-left: 10rem;
	margin-right: 10rem;
}

.field_filter-#{$class_PH},
.label_filter-#{$class_PH} {
	@include F_UR(14rem);
	color: $c_f_text_dark;
}

.bttn_filter-#{$class_PH} {
	@extend %bttn_common;
	width: 80rem;
}

.field_filter-#{$class_PH},
.label_filter-#{$class_PH},
.bttn_filter-#{$class_PH} {
	display: inline-block;
	vertical-align: middle;
}
