@mixin F_UL($size) {
    font-weight: 400;
    font-size: $size;
    font-family: 'OpenSans-Light', sans-serif;
}
@mixin F_OSL($size) {
    font-weight: 400;
    font-size: $size;
    font-family: 'OpenSans-Light', sans-serif;
}
@mixin F_UR($size) {
    font-weight: 400;
    font-size: $size;
    font-family: 'OpenSans-Regular', sans-serif;
}
@mixin F_OSR($size) {
    font-weight: 400;
    font-size: $size;
    font-family: 'OpenSans-Regular', sans-serif;
}
@mixin F_UM($size) {
    font-weight: 400;
    font-size: $size;
    font-family: 'OpenSans-SemiBold', sans-serif;
}
@mixin F_OSSB($size) {
    font-weight: 400;
    font-size: $size;
    font-family: 'OpenSans-SemiBold', sans-serif;
}
@mixin F_UB($size) {
    font-weight: 400;
    font-size: $size;
    font-family: 'OpenSans-Bold', sans-serif;
}
@mixin F_OSB($size) {
    font-weight: 400;
    font-size: $size;
    font-family: 'OpenSans-Bold', sans-serif;
}
$font-sizes: (
    'l_h': 34rem,
    'l_t': 18rem,
    'm_t': 16rem,
    's_t': 14rem,
);
@function getFS($key) {
    @return map-get($font-sizes, $key)
}
@import "colors.scss";
@import "sizes.scss";
@import "icons/icons.scss";
$trans3: 0.3s;
$trans5: 0.5s;
$trans10: 1s; // ----------------------------------------------------------------------------
@mixin bp($size) {
    @if $size==desktop {
        @media screen and (min-width: $desktop) {
            @content;
        }
    } @else
    if $size==laptop {
        @media screen and (max-width: $laptop - 1px) {
            @content;
        }
    } @else
    if $size==tablet {
        @media screen and (max-width: $tablet - 1px) {
            @content;
        }
    } @else
    if $size==mobile {
        @media screen and (max-width: $mobile - 1px) {
            @content;
        }
    }
}
@mixin pixelRatio($ratio) {
    @if $ratio==2 {
        @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2) {
            @content
        }
    }
}
@mixin ph($font-name, $font-size, $font-color) {
    &::-webkit-input-placeholder {
        font-family: $font-name, sans-serif;
        font-size: $font-size;
        color: $font-color;
        transition: $trans3;
        @content;
    }

    &::-moz-placeholder {
        font-family: $font-name, sans-serif;
        font-size: $font-size;
        color: $font-color;
        transition: $trans3;
        @content;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
        font-family: $font-name, sans-serif;
        font-size: $font-size;
        color: $font-color;
        transition: $trans3;
        @content;
    }

    &::placeholder {
        font-family: $font-name, sans-serif;
        font-size: $font-size;
        color: $font-color;
        transition: $trans3;
        @content;
    }

    &:placeholder {
        font-family: $font-name, sans-serif;
        font-size: $font-size;
        color: $font-color;
        transition: $trans3;
        @content;
    }
}
// Abstract classes

%clear {
    position: relative;

    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

%shim {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
@mixin clear {
    position: relative;

    &:after {
        content: '';
        display: block;
        clear: both;
    }
}

%table_row {
    position: relative;
    display: table-row;
    border-collapse: collapse;
}
@mixin table_row_mob {
    display: block;
    height: auto;
    border-top: 1rem solid $c_bd_table;
    border-bottom: 1rem solid $c_bd_table;
    margin-left: -$main_content_hz_pad;
    margin-right: -$main_content_hz_pad;
    padding-left: $main_content_hz_pad;
    padding-right: $main_content_hz_pad;
}
@mixin table_cell_mob {
    @include clear;
    display: block;
    width: 100%;
    padding: 0;
    min-width: 0;
    height: $row_h_mob;
    line-height: $row_h_mob;
    border: none;
}
@mixin trans($property, $time) {
    @if $property==bg {
        transition-property: background-color;
    }
    @elseif $property==h {
        transition-property: height;
    } @else {
        transition-property: $property;
    }
    @if $time==st {
        transition-duration: 0.3s;
    } @else {
        transition-duration: $time;
    }
}
@mixin icon {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 25rem;
        height: 25rem;
    }
}

%block_shadow {
    box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.1);
}

%bttn_general {
    width: 218rem;
    height: 44rem;
    padding-left: 10rem;
    padding-right: 10rem;
    text-align: center;
    line-height: 44rem;
    color: $c_f_bttn_white;
    background-color: $c_main_red;
    cursor: pointer;
    transition: $trans3;
    opacity: 1;
    @include F_OSSB(18rem);

    &:hover {
        //background-color: $c_bg_bttn_hover;
    }

    &.is-disabled {
        opacity: 0.15;
        cursor: default;
    }
}

%bttn_common {
    @extend %bttn_general;
    margin-left: auto;
    margin-right: auto;
    //transition: background-color $trans3;
}

%input_text {
    @include F_UL(16rem);
    color: $c_f_text_dark;
    padding-left: 10rem;
    padding-right: 10rem;
    line-height: 1.62;
    margin-top: 15rem;
}

%table_border {
    /*Add for child elems in .row_content*/
    position: relative;
    transition: border-color $trans3;

    &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        border-top: 1px solid transparent;
        transition: border-color $trans3;
    }
}
@mixin table_border_hover($row_content_child_selector) {
    @if $row_content_child_selector==row {
        .wrapper_row_content:hover {
            border-color: $c_main_red;

            &:before {
                border-color: $c_main_red;
            }

            &:first-of-type {
                &:before {
                    border-color: $c_main_red - #333333;
                }
            }
        }
    } @else {
        &:hover {
            > #{$row_content_child_selector}:before,
            > #{$row_content_child_selector} {
                border-color: $c_main_red;
            }

            &:first-of-type > #{$row_content_child_selector}:before {
                border-color: $c_main_red - #333333;
            }
        }
    }
}

%vmid {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

%block_common {
    background-color: $c_bg_block_white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
