$block_notification_h: 75rem;
$transition: 0.5s;

.block_notification-error {
    z-index: 1002;
}

.block_notification-success {
    z-index: 1000;
}

.block_notification-error,
.block_notification-success {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $block_notification_h;
    transform: translateY(-100%);
    transition: $transition;
    line-height: $block_notification_h;
    text-align: center;
    padding-left: 40rem;
    padding-right: 40rem;

    &.is-visible {
        transform: translateY(0);
    }
}

.wrapper_text-notification {
    display: inline-block;
    line-height: 20rem;
}

.icon_notification {
    display: inline-block;
    width: 20rem;
    height: 20rem;
    background-position: center;
    background-size: cover;
    margin-right: 32rem;
    vertical-align: bottom;
}

.text_notification {
    display: inline-block;
    color: $c_f_bttn_white;
    @include F_OSR(16rem);
    vertical-align: middle;
}

.icon_close-notification {
    position: absolute;
    cursor: pointer;
    right: 50rem - 20rem + 5rem;
    top: 32rem - 20rem + 5rem;
    width: 40rem;
    height: 40rem;

    &:before {
        content: '';
        position: absolute;
        top: 15rem;
        left: 15rem;
        width: 11rem;
        height: 11rem;
        background-position: center;
        background-size: cover;
    }
}

.block_notification-error {
    background-color: $c_bg_error_red;

    .icon_notification {
        @extend %icon_alert;
    }

    .icon_close-notification {
        &:before {
            @extend %icon_close_error;
        }
    }
}

.block_notification-success {
    background-color: $c_main_green;

    .icon_notification {
        @extend %icon_check_green;
        background-color: $c_bg_block_white;
        background-size: 12rem 9rem;
    }

    .icon_close-notification {
        &:before {
            @extend %icon_close_notification;
        }
    }
}
