.wrapper_content-animated-dashboard {
    overflow: hidden;
    transition: 1s;

    .block_content-AV,
    .block_content-CW {}

    &.is-hidden {
        height: 0;

        .wrapper_services-enabled {}

        .block_content-AV,
        .block_content-CW {
            //opacity: 0;
        }
    }

    &.is-visible {
        .wrapper_services-enabled {
            position: relative;
        }

        .block_content-AV,
        .block_content-CW {
            //opacity: 1;
        }
    }
}

.wrapper_services-enabled {
    $row_h: 100rem;
    position: relative;
    padding-bottom: $row_h;

    .button_toggle-details {
        bottom: $row_h / 2;
        transform: translateY(50%);
    }
}

.table_elem-dashboard {
    padding-bottom: 35rem;
}
$full_w: 1290rem;
$cls_CW: 'CW';
$cls_AV: 'AV';

.cell {
    &_period {
        &-#{$cls_AV},
        &-#{$cls_CW} {
            width: percentage(312rem/$full_w);
            padding-right: 20rem;
        }
    }

    &_operations-#{$cls_AV},
    &_users-#{$cls_CW} {
        padding-right: 20rem;
    }

    &_users-#{$cls_CW} {
        width: percentage(212rem/$full_w);
    }

    &_operations-#{$cls_AV} {}

    &_price {
        &-#{$cls_AV},
        &-#{$cls_CW} {
            padding-right: 20rem;
        }

        &-#{$cls_CW} {
            width: percentage(230rem/$full_w);
        }

        &-#{$cls_AV} {}
    }

    &_subscription {
        &-#{$cls_AV} {
            //width: percentage(230rem/$full_w);
            padding-right: 20rem;
        }
    }

    &_total {
        &-#{$cls_AV},
        &-#{$cls_CW} {
            padding-right: 20rem;
        }

        &-#{$cls_CW} {
            width: percentage(210rem/$full_w);
        }

        &-#{$cls_AV} {}
    }

    &_status {
        &-#{$cls_AV},
        &-#{$cls_CW} {
            //width: percentage(326rem/$full_w);
            padding-right: 20rem;
            @include bp(laptop) {
                width: 120rem;
            }
        }
    }
}

.text {
    &_period {
        &-#{$cls_AV},
        &-#{$cls_CW} {
            white-space: pre-wrap;
            @include F_OSR($fs20);
            color: $c_f_text_dark;
        }
    }

    &_operations-#{$cls_AV},
    &_users-#{$cls_CW} {
        @include F_OSSB($fs24);
        color: $c_f_text_dark;
    }

    &_price {
        &-#{$cls_AV},
        &-#{$cls_CW} {
            @include F_OSSB($fs24);
            color: $c_f_text_dark;
        }
    }

    &_subscription {
        &-#{$cls_AV} {
            @include F_OSSB($fs24);
            color: $c_f_text_dark;
        }
    }

    &_total {
        &-#{$cls_AV},
        &-#{$cls_CW} {
            @include F_OSB($fs24);
            color: $c_f_text_dark;
        }
    }

    &_status {
        &-#{$cls_AV},
        &-#{$cls_CW} {
            @include F_OSR($fs24);

            &.is-pending {
                color: $c_f_text_dark;
            }

            &.is-paid {
                color: $c_f_text_grey;
            }
        }
    }
}
//
// .cell_subscription-AV
//     .text_subscription-AV $1000
