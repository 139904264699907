* {
    box-sizing: border-box;
    background-repeat: no-repeat;
}

@media print {
    @page {
        size: portrait
    }
}

*:before,
*:after {
    background-repeat: no-repeat;
}

html {
    font-size: 1px; // (100vw / 1440);
    @media print {
        font-size: 0.3mm !important
    }
}

body {
    height: 100%;
    min-height: 100vh;
    &.is-fixed {
        overflow: hidden;
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
    }
}

.wrapper_page {
    position: relative;
    background-color: $c_bg_main_light;
    min-height: 100vh;
}

.clickable {
    cursor: pointer
}

main {
    @extend %clear;
    background-color: $c_bg_main_grey;
    padding-left: $main_left_offset;
    padding-bottom: $main_bottom_offset;
    padding-right: $main_right_offset; 
    margin-left: $menu_w;
    min-height: 100vh;
    @include bp(tablet) {
        margin-left: 0;
    }
    @media print {
        margin-left: 0
    }
}

.wrapper_content {}

.block_content {
    @extend %block_common;
    padding-left: 30rem;
    padding-right: 30rem;
    padding-bottom: 30rem;
    min-height: calc(100vh - #{$header_h} - #{$main_bottom_offset});
}

.header_block {
    @extend %clear;
    min-height: $header_h;
    line-height: $header_h;
}

.title_block {
    @include F_OSR(34rem);
    color: $c_f_text_dark;
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
}

.general_wrapper {
    overflow-y: scroll;
}


/*main {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: stretch;
}*/
