html,
body {
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
	background-repeat: no-repeat;
	&:before,
	&:after {
		background-repeat: no-repeat;
	}
}

input {
	border: none;
	border-radius: 0;
	padding: 0;
	position: relative;
	&:focus {
		outline: none;
	}
	&[type="file"] {
		display: none;
	}
	&::-webkit-contacts-auto-fill-button,
	&::-webkit-credentials-auto-fill-button/*,
	&::-webkit-textfield-decoration-container*/ {
		visibility: hidden;
		pointer-events: none;
		position: absolute;
		right: 0;
	}
}

textarea {
	border: none;
	resize: none;
	&:focus {
		outline: none;
	}
}

button {
	display: block;
	margin: auto;
	cursor: pointer;
	border: none;
	padding: 0;
	background-color: transparent;
	border-radius: 0;
	&:focus {
		outline: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}

a {
	&:link,
	&:visited,
	&:active,
	&:hover {
		text-decoration: none;
		color: inherit;
		outline: none;
	}
}

figure {
	margin: 0
}
