.group_field {
    position: relative;
    max-width: 406rem;
    margin-bottom: 40rem;

    &.is-invalid {
        .Select-control,
        .field_input {
            border-color: $c_main_red;

            &:focus + .block_field-error-description {
                display: block;
            }
        }

        .icon_field-error {
            display: block;
        }

        &.is-focused {
            .block_field-error-description {
                display: block;
            }
        }
    }

    &.is-readonly {
        cursor: default;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;

        .field_input {
            opacity: 0.5;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            pointer-events: none;
        }

        .label_input {
            //display: none;
        }
    }
    /*&.is-valid {
        .form-input {
            padding-right: 40rem;
        }
        &:before {
			position: absolute;
			content: '';
			width: 18rem;
			height: 18rem;
			right: 10rem;
			top: 15rem;
			margin-top: -9rem;
			background-size: contain;
			background-position: center;
			@extend %icon_ok;
		}
    }*/
}

.icon_field-error {
    display: none;
    @extend %icon_alert;
    position: absolute;
    width: 35rem;
    height: 40rem;
    background-size: 21rem 21rem;
    background-position: center center;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    cursor: pointer;
    z-index: 1;

    &:hover + .field_input + .block_field-error-description {
        display: block;
    }
}

.field_input {
    display: block;
    width: 100%;
    height: $form_field_h;
    padding: 5rem 10rem;
    border: solid 1px $c_bd_input_grey;
    @include F_OSR(14rem);
}

.label_input {
    position: absolute;
    left: 10rem;
    top: -23rem;
    @include F_OSR(14rem);
    color: $c_f_text_grey;
    pointer-events: none;
    transition: 0.2s ease all;
}
/* active state */
.block_field-error-description {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    transform: translateY(10rem);
    border-radius: 5rem;
    padding: 16rem;
    text-align: center;
    background-color: $c_bg_input_error;
    z-index: 3;

    &:before {
        $side: 11rem;
        $offset: 4rem;
        content: '';
        position: absolute;
        width: $side;
        height: $side;
        transform: rotate(45deg);
        background-color: $c_bg_input_error;
        top: -$offset;
        left: 50%;
        margin-left: -$offset;
    }
}

.text_field-error-description {
    @include F_OSR(14rem);
    color: $c_f_bttn_white;
    line-height: 1.4;
}
/* active state */
textarea {
    height: 31rem;
    max-height: 300rem;
    overflow: hidden;
}

input {
    background-color: transparent;
    -webkit-rtl-ordering: visual;
    text-align: left;

    &::-webkit-credentials-auto-fill-button {
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        right: 0;
    }
}

.textarea-size-measure {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    line-height: 1.2;
    word-break: break-word;
    white-space: pre-wrap;
    font-size: 18rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    pointer-events: none;
}

textarea {
    min-height: 31rem;
    height: 31rem;
    max-height: 300rem;
    overflow: hidden;
}

.field_password {
    //font-family: "text-security-disc";
}

.group_dropdown {
    z-index: 2;
}
